import React, { useState, useEffect, useRef } from "react";
import { FaBars } from "react-icons/fa"; // Importando o ícone de menu sanduíche
import logo from "../../assets/logo/mascote-uniforme-preto.png";
import logoCabecalho from "../../assets/logo/img-nova-cabecalho-jornal-saltarelli.png";
import "./logo.css"; // Importando o arquivo CSS

function Logo() {
  const [menuOpen, setMenuOpen] = useState(false); // Estado para controlar o menu sanduíche
  const dropdownRef = useRef(null); // Referência ao dropdown menu

  const handleScroll = (e, target) => {
    e.preventDefault();
    const element = document.querySelector(target);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const toggleMenu = () => {
    setMenuOpen((prev) => !prev); // Alterna o estado do menu
  };

  // Função para fechar o dropdown quando clica fora
  const handleClickOutside = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      !event.target.classList.contains("menu-icon")
    ) {
      setMenuOpen(false); // Fecha o dropdown
    }
  };

  // Adiciona o event listener ao montar o componente
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside); // Remove o listener ao desmontar
    };
  }, []);

  return (
    <div>
      {/* Placeholder para ocupar o espaço da logo */}
      <div className="logo-placeholder"></div>

      <div className="logo-container">
        <div className="sticky-header">
          <div className="background-logo">
            <img
              src={logoCabecalho}
              alt="Logo do cliente"
              className="logo-img"
            />
          </div>

          {/* Links abaixo da logo, também sticky */}
          <nav className="header-links">
            {/* <a
              href="#hortifruti"
              onClick={(e) => handleScroll(e, "#hortifruti")}
            >
              HORTIFRUTI
            </a> */}

            <a
              href="#promocoes"
              className="promocoes-title"
              onClick={(e) => handleScroll(e, "#promocoes", "#acougue")}
            >
              PROMOÇÕES
            </a>

            <a
              href="#acougue"
              onClick={(e) => handleScroll(e, "#promocoes", "#acougue")} // Mesmo comportamento do primeiro
            >
              AÇOUGUE
            </a>

            {/* <a href="#mercearia" onClick={(e) => handleScroll(e, "#mercearia")}>
              MERCEARIA
            </a> */}
            <a href="#padaria" onClick={(e) => handleScroll(e, "#padaria")}>
              PADARIA
            </a>
            {/* Ícone de menu sanduíche
            <FaBars
              className="menu-icon"
              style={{ fontSize: "2.0rem", padding: "5px" }}
            /> */}
          </nav>

          {/* <div className="ofertas-validas">
            OFERTAS VÁLIDAS DE 14/10 a 30/10
          </div> */}

          {/* Menu dropdown para mobile quando o menu sanduíche é clicado */}
          {menuOpen && (
            <div className="dropdown-menu" ref={dropdownRef}>
              <a href="#contato" onClick={(e) => handleScroll(e, "#contato")}>
                CONTATO
              </a>
              <a
                href="https://chat.whatsapp.com/GdYrxmhf7xq3VcdIxXN1Vl" // Link para o grupo do WhatsApp
                target="_blank"
                rel="noopener noreferrer"
                onClick={(e) => {
                  e.preventDefault(); // Previne a ação padrão de apenas rolar a página
                  window.open(
                    "https://chat.whatsapp.com/GdYrxmhf7xq3VcdIxXN1Vl",
                    "_blank"
                  );
                }}
              >
                GRUPO DE OFERTAS
              </a>
              <a
                href="#horarioAtendimento"
                onClick={(e) => handleScroll(e, "#horarioAtendimento")}
              >
                HORÁRIO DE ATENDIMENTO
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Logo;
