import React, { useEffect, useRef, useState } from "react";
import "./promocoes.css";
import "../global.css";
import logoPromocao from "./logo-promocao/black-friday-saltarelli.png";
import energeticoMonsterGreen from "./images-promocoes/energeticoMonsterGreen.png";
import amstelFardoLata350ml from "../../assets/amstel-lata.png";
import cervejaBrahmaLata from "../../assets/brahma-350ml.png";
import crystalLataFardo from "../../assets/crystal-lata.png";
import paoFrances from "./images-promocoes/pao.png";
import paoDeAlho from "../../assets/pao_de_alho_mencucini.png";
import lasanha_seara from "../../assets/lasanha_seara.png";
import paodequeijo from "./images-promocoes/paodequeijo.png";
import cocaColaZero from "./images-promocoes/cocaColaZero.png";
import sucoDelValleSabores from "./images-promocoes/sucoDelValleSabores.png";
import cuidadosComCabelo from "./images-promocoes/cuidadosComCabelo.png";
import churrasco_ContraFile_Frango from "./images-promocoes/churrasco_CarneBovina_Frango.png";
import tulipa_Temperada_Fundo_Preto from "./images-promocoes/tulipa_Temperada_FundoPreto.png";
import coxinha_da_asa from "../../assets/coxinha-da-asa.png";
import cerveja_sabores from "./images-promocoes/cervejas_sabores.png";
import pontaAlcatraAngus from "./images-promocoes/pontaAlcatraAngus.png";
import carneMoida from "../../assets/carne-moida.png";
import paleta_bovina from "./images-promocoes/paleta_bovina.png";
import cervejaLataImperio from "../../assets/imperio-lata.png";
import whatssappIcon from "./images-promocoes/whatssapp.svg";
import cervejaPuroMalte from "../../assets/puro-malte-lata.png";
import kitChurrasco from "./images-promocoes/kit-churrasco.png";
import videoFilao from "../../assets/videoFilao.mp4";
import vinhoParatudo from "../../assets/vinhoParatudo.png";
import vinhoBordo from "../../assets/vinhoTintoBordo.png";
import pingaPirassununga from "../../assets/pingaPirassununga.png";
import pingaMoenda from "../../assets/pingaMoenda.png";
import guaranaMaca from "../../assets/guaranaMaca.png";
import paoFrancesCapa from "../../assets/PaoFrancesCapa.png"; // importando a imagem da capa
import capavideoBalcaoAcougue from "../../assets/capa_balcao_acougue_mascote.png";
import costelaBafo from "../../assets/costela.png";
import costelinha_Suina from "../../assets/costelinha_Suina.png";
import pontaDePeito from "../../assets/ponta_de_Peito.png";
import budweiserFardolata350ml from "../../assets/budweiser-lata.png";
import cerveja_Spaten_lata from "../../assets/cerveja_Spaten_lata.png";
import cervejaAntartictaBoa from "../../assets/cerveja-antarctica-boa-350ml.png";
import carvao_pitoko_3KG from "../../assets/carvao-pitoko-3kg.png";
import carvao_pitoko_8KG from "../../assets/carvao-pitoko-8kg.png";
import pernilSemOsso from "../../assets/pernil-sem-osso.png";
import panceta from "../../assets/panceta.png";
import linguica_churrasco from "../../assets/linguica_churrasco.png";
import linguicaCaipira from "../../assets/linguica_caipira.png";
import linguicaToscana from "../../assets/linguica_Toscana.png";
import fileFrango from "../../assets/filePeitoFrango.png";
import frango_Passarinho from "../../assets/frango_Passarinho.png";
import video_acougue from "../../assets/video_acougue.mp4";
import VideoPlayer from "../Video_Player";
import cervejaHeinekenLata from "../../assets/heiniken-lata-350ml.png";
import skollata from "../../assets/skol-lata.png";
import cervejaSubzeroLata from "../../assets/cerveja-subzero-350ml.png";
import figadoBovino from "../../assets/figado_Bovino.png";
import guaranaFabri from "../../assets/guaranaFabri2l.png";
import paoAlhoLutMinas from "../../assets/pao-alho-lut-minas.png";
import ginBeverley from "../../assets/gin-beverley.png";
import ginIntencion from "../../assets/gin-intencion.png";
import vodkaOlsk from "../../assets/vodka-olsk.png";
import askovRemix from "../../assets/askov-maracuja.png";
import coroteSabores from "../../assets/corote-sabores.png";
import energeticoMonsterSabores from "../../assets/energetico-monster.png";
import aguaMinalba from "../../assets/agua-minalba.png";
import energeticoVibe from "../../assets/energetico-vibe.png";
import criativoPulsionar from "../../assets/image-pulsionamento.jpg";
import coxaSobrecoxa from "../../assets/coxa_Sobrecoxa.png";
import vinho_mioranza from "../../assets/vinho_mioranza.png";
import vinhoSantoExpedito from "../../assets/vinhosantoexpedito.png";
import guaranaCruzeiro from "../../assets/guaranacruzeiro.png";
import sucoTampico from "../../assets/sucotampico.png";
import contraFileGrill from "../../assets/contra-file-grill.png";
import bistecaSuina from "../../assets/bisteca_Suina.png";
import heineken from "../../assets/heiniken-lata-350ml.png";
import toucinhoPicado from "../../assets/toucinhoPicado.jpeg";
import linguicaFina from "../../assets/linguicaFina.jpeg";
import linguicaCalabresa from "../../assets/linguicaCalabresa.jpeg";
import budweiserlata350ml from "../../assets/budweiserLata.jpeg";
import eisenbahnLata from "../../assets/eisenbahnLata.jpeg";
import { FaPlay, FaPause } from "react-icons/fa";

function Promocoes() {
  return (
    <div className="container-ofertas" id="promocoes">
      <div className="tag-promocoes-container">
        <a
          href="https://chat.whatsapp.com/GdYrxmhf7xq3VcdIxXN1Vl"
          target="_blank"
          rel="noopener noreferrer"
          className="whatsapp-fixo"
          id="grupoOfertas"
        >
          <img
            src={whatssappIcon}
            alt="WhatsApp"
            id="whatsappIcon"
            style={{ width: "55px", height: "auto" }}
          />

          <div className="tooltip" id="whatsappTooltip">
            FAÇA PARTE DO GRUPO DO SALTARELLI
          </div>
        </a>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />

        <div className="criativo-impulsionar">
          <img
            src={criativoPulsionar}
            alt="Logo do cliente"
            className="criativo-impulsionar"
          />
        </div>
        <br />
        <div className="promocoes-container" id="acougue">
          <div className="promo-item">
            <img src={costelaBafo} alt="Costela Bafo" />
            <p>Costela Bafo</p>
            <div className="title-oferta-medio">
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>25,99
                <span className="valor-unidade">Kg.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img src={pontaDePeito} alt="Ponta de Peito" />
            <p>Ponta de Peito</p>
            <div className="title-oferta-medio">
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>33,99
                <span className="valor-unidade">Kg.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img src={pontaAlcatraAngus} alt="pontaAlcatraAngus" />
            <p>Ponta de Alcatra</p>
            <div className="title-oferta-medio">
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>42,99
                <span className="valor-unidade">Kg.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img src={contraFileGrill} alt="Contra Filé Grill" />
            <p>Contra Filé Grill</p>
            <div className="title-oferta-medio">
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>54,99
                <span className="valor-unidade">Kg.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img src={pernilSemOsso} alt="Pernil Sem Osso" />
            <p>Pernil Sem Osso</p>
            <div className="title-oferta-medio">
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>22,99
                <span className="valor-unidade">Kg.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img src={bistecaSuina} alt="Bisteca Suína" />
            <p>Bisteca Suína</p>
            <div className="title-oferta-medio">
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>20,99
                <span className="valor-unidade">Kg.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img src={toucinhoPicado} alt="Tousinho Suíno Picado" />
            <p>Toucinho Suíno Picado</p>
            <div className="title-oferta-medio">
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>19,99
                <span className="valor-unidade">Kg.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img src={panceta} alt="Tousinho Suíno Picado" />
            <p>Panceta Suína</p>
            <div className="title-oferta-medio">
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>29,99
                <span className="valor-unidade">Kg.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img
              src={linguica_churrasco}
              alt="Linguinça Toscana para Churrasco"
            />
            <p>Linguinça Toscana para Churrasco</p>
            <div className="title-oferta-medio">
              <p className="embalagem-info"></p>
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>19,99
                <span className="valor-unidade">Kg.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img src={linguicaCaipira} alt="Linguica Pernil" />
            <p>Linguica Pernil</p>
            <div className="title-oferta-medio">
              <p className="embalagem-info"></p>
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>19,99
                <span className="valor-unidade">Kg.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img src={linguicaFina} alt="Linguica Fina com ou sem Pimenta" />
            <p>Linguica Fina com ou sem Pimenta</p>
            <div className="title-oferta-medio">
              <p className="embalagem-info"></p>
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>28,99
                <span className="valor-unidade">Kg.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img src={linguicaCalabresa} alt="Linguica Calabresa" />
            <p>Linguica Calabresa</p>
            <div className="title-oferta-medio">
              <p className="embalagem-info"></p>
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>2,69
                <span className="valor-unidade">cada 100g</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img src={heineken} alt="cerveja Lata Heineken" />
            <p> Heineken lata 350ml </p>
            <div className="title-oferta-medio">
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>4,99
                <span className="valor-unidade">Unid.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img src={budweiserlata350ml} alt="cerveja Lata Budweiser" />
            <p> Budweiser lata 350ml </p>
            <div className="title-oferta-medio">
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>4,29
                <span className="valor-unidade">Unid.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img src={eisenbahnLata} alt="cerveja Lata Eisenbahn" />
            <p> Eisenbahn lata 350ml </p>
            <div className="title-oferta-medio">
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>4,39
                <span className="valor-unidade">Unid.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img src={skollata} alt="cerveja Lata Skol" />
            <p> Skol lata 350ml </p>
            <p className="embalagem-info">Fardo c/18 R$ 59,99</p>
            <div className="title-oferta-medio">
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>3,33
                <span className="valor-unidade">Unid.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img src={cervejaAntartictaBoa} alt="Antarctica BOA" />
            <p> Antarctica BOA Lata 350ml</p>
            <p className="embalagem-info">Fardo c/18 R$ 55,00</p>
            <div className="title-oferta-medio">
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>3,05
                <span className="valor-unidade">Unid.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img src={cervejaBrahmaLata} alt="Brahma lata 350ml" />
            <p> Brahma lata 350ml </p>
            <p className="embalagem-info">Fardo c/18 R$ 59,99</p>
            <div className="title-oferta-medio">
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>3,33
                <span className="valor-unidade">Unid.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img src={cervejaPuroMalte} alt="cerveja Puro Malte" />
            <p> Puro Malte 3.0 lata 350ml </p>
            <p className="embalagem-info">Fardo c/12 R$ 30,00</p>
            <div className="title-oferta-medio">
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>2,50
                <span className="valor-unidade">Unid.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img src={crystalLataFardo} alt="cristal Lata Fardo" />
            <p> Crystal lata 350ml</p>
            <p className="embalagem-info">Fardo c/12 R$ 28,50</p>
            <div className="title-oferta-medio">
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>2,37
                <span className="valor-unidade">Unid.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img src={cervejaLataImperio} alt="Império Fardo Lata 350ml" />
            <p> Império lata 350ml </p>
            <p className="embalagem-info">Fardo c/15 R$ 45,75</p>
            <div className="title-oferta-medio">
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>3,05
                <span className="valor-unidade">Unid.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img src={sucoTampico} alt="Suco Tampico 2L" />
            <p> Suco Tampico 2L </p>
            <div className="title-oferta-medio">
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>11,98
                <span className="valor-unidade">Unid.</span>
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Promocoes;
