import "./footer.css";
import { FaWhatsapp, FaInstagram, FaPhone, FaFacebook } from "react-icons/fa"; // Biblioteca de ícones FontAwesome
import logoMascotePoster from "./footer-images/logo-com-mascote.png";
import imgRotasSaltarelli from "./footer-images/img-maps-saltarelli.png";

function Footer() {
  return (
    <footer className="footer container-ofertas">
      <div className="ofertas-validas">
        OFERTAS VÁLIDAS DE 14/03/2025 a 29/03/2025
      </div>

      <h3 className="titulo-video">Faça um Tour Exclusivo pela Nossa Loja</h3>
      <br />
      <div className="video-wrapper">
        <video
          controls
          poster={logoMascotePoster}
          className="video-apresentacao"
        >
          <source src="/videoApresentacao.net.mp4" type="video/mp4" />
          Seu navegador não suporta vídeo HTML5.
        </video>
      </div>

      <div className="footer-section">
        <div className="endereco-bts">
          <div id="horarioAtendimento"></div>
          <strong>Venha nos visitar em:</strong>
          <strong>Rua Dargino Ceribelli 160, Batatais - SP, 14300-000</strong>
          {/* Mapa do Google via iframe */}
          <br />
          <br />
          <a
            className="acesso-rotas"
            href="https://www.google.com/maps/dir//Rua+Dargino+Ceribelli,+160,+Batatais+-+SP,+14300-000/@-20.8869232,-47.573419,17z/data=!4m9!4m8!1m0!1m5!1m1!1s0x94b9fae760f0a755:0xab6f20ec53aeb6fe!2m2!1d-47.573419!2d-20.8869232!3e0?entry=ttu"
            target="_blank" // Abre em nova aba
            rel="noopener noreferrer" // Segurança ao abrir em nova aba
          >
            Clique aqui e acesse as rotas
          </a>
          <div
            className="tlogo-hortfruti"
            onClick={() =>
              window.open(
                "https://www.google.com/maps/dir//Rua+Dargino+Ceribelli,+160,+Batatais+-+SP,+14300-000/@-20.8869232,-47.573419,17z/data=!4m9!4m8!1m0!1m5!1m1!1s0x94b9fae760f0a755:0xab6f20ec53aeb6fe!2m2!1d-47.573419!2d-20.8869232!3e0?entry=ttu",
                "_blank"
              )
            }
          >
            <img
              src={imgRotasSaltarelli}
              alt="Hortifruti"
              className="imagem-rotas"
            />
          </div>
        </div>
      </div>

      {/* Seção Horário de Atendimento e Fale Conosco */}
      <div className="footer-section video-col" id="horarioAtendimento">
        <div className="footer-section horario-fale-container horario-col">
          <div className="">
            <h3>Horário de Atendimento</h3>
            <p className="subtitulo-horario-atendimento">
              Aberto todos dias para melhor te atender
            </p>
            <div id="contato"></div>
            <ul>
              <li>
                <strong>Domingo:</strong>{" "}
                <span className="horario">07:00 às 19:00</span>
              </li>
              <li>
                <strong>Segunda-feira:</strong>{" "}
                <span className="horario">06:00 às 21:00</span>
              </li>
              <li>
                <strong>Terça-feira:</strong>{" "}
                <span className="horario">06:00 às 21:00</span>
              </li>
              <li>
                <strong>Quarta-feira:</strong>{" "}
                <span className="horario">06:00 às 21:00</span>
              </li>
              <li>
                <strong>Quinta-feira:</strong>{" "}
                <span className="horario">06:00 às 21:00</span>
              </li>
              <li>
                <strong>Sexta-feira:</strong>{" "}
                <span className="horario">06:00 às 21:00</span>
              </li>
              <li>
                <strong>Sábado:</strong>{" "}
                <span className="horario">06:00 às 21:00</span>
              </li>
            </ul>
          </div>
          <div className="fale-conosco">
            <h3>Fale Conosco</h3>
            <p>
              <FaPhone style={{ color: "#121212" }} /> Telefone:{" "}
              <a href="tel:+551636623003" style={{ color: "#121212" }}>
                +55 16 3662-3003
              </a>
            </p>
            <p>
              <FaWhatsapp style={{ color: "#25d366" }} /> WhatsApp:{" "}
              <a
                href="https://chat.whatsapp.com/GdYrxmhf7xq3VcdIxXN1Vl"
                target="_blank"
                rel="noopener noreferrer"
              >
                +55 16 99238-1823
              </a>
            </p>
            <p>
              <FaInstagram style={{ color: "#E4405F" }} /> Instagram:{" "}
              <a
                href="https://instagram.com/supermercadoSaltarelli"
                target="_blank"
                rel="noopener noreferrer"
              >
                Supermercado Saltarelli
              </a>
            </p>
            <p>
              <FaFacebook
                className="facebook-icon"
                style={{ color: "#1877F2" }}
              />{" "}
              Facebook:{" "}
              <a
                href="https://www.facebook.com/share/TYkj7Am4scKvPT7L/?mibextid=LQQJ4d"
                target="_blank"
                rel="noopener noreferrer"
              >
                Supermercado Saltarelli no Facebook
              </a>
            </p>

            <br />
            <div className="social-icons">
              <a href="tel:+551636623003">
                <FaPhone style={{ color: "#121212" }} />
              </a>
              <a
                href="https://wa.me/5516992381823"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaWhatsapp style={{ color: "#25d366" }} />
              </a>
              <a
                href="https://instagram.com/supermercadoSaltarelli"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaInstagram style={{ color: "#E4405F" }} />
              </a>
              <a
                href="https://www.facebook.com/share/TYkj7Am4scKvPT7L/?mibextid=LQQJ4d"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaFacebook style={{ color: "#1877F2" }} />
              </a>
            </div>
          </div>

          {/* Destaque do WhatsApp */}
          <div className="destaque-whatsapp">
            <p>
              <strong>
                FAÇA PARTE DO GRUPO E CONCORRA A SORTEIOS E FIQUE POR DENTRO DAS
                PROMOÇÕES.
              </strong>
            </p>
            <a
              href="https://chat.whatsapp.com/GdYrxmhf7xq3VcdIxXN1Vl"
              target="_blank"
              rel="noopener noreferrer"
              className="whatsapp-button"
              id="grupoOfertas"
            >
              <FaWhatsapp style={{ marginRight: "5px" }} /> Entrar no grupo
            </a>
          </div>
          <div id="section-tree">
            {/* Copyright */}
            <div className="footer-section rights">
              <strong>
                © Supermercado Saltarelli. Todos os direitos reservados.
              </strong>
            </div>

            <div className="informativo">
              <p>
                As fotos deste jornal digital são meramente ilustrativas. As
                mercadorias em promoção têm quantidades limitadas por cliente,
                de acordo com o Artigo 39 do Código de Defesa do Consumidor (Lei
                n°. 8.078, de 11/09/90) e o Artigo 12 do Decreto n° 2.181, de
                20/03/97.
                <br />
                É proibida a venda e entrega de bebidas alcoólicas a menores de
                18 anos, conforme o Artigo 81, II, do Estatuto da Criança e do
                Adolescente.
                <br />
                Em caso de erros de digitação ou impressão, nos reservamos o
                direito de corrigi-los, mantendo o compromisso com o Código de
                Defesa do Consumidor.
              </p>
            </div>

            <strong>Desenvolvido por: LD WebSites</strong>
            <div className="footer-section my-work">
              <a
                href="https://wa.me/551637611419?text=Ol%C3%A1%20seja%20bem-vindo%20a%20WebSites,%20Usando%20a%20Tecnologia%20a%20seu%20favor!%20Gostaria%20de%20agendar%20uma%20reuni%C3%A3o,%20hor%C3%A1rio%20dispon%C3%ADvel?"
                target="_blank"
                rel="noopener noreferrer"
              ></a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
